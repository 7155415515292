import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { TextField } from "formik-mui";
import { useEffect, useState } from "react";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Config } from "../../utils/commonFuctions/Token";
import { base_url } from "../backendurl/url";
import CardComponent from "./CardComponent";
import dash1 from "../../images/dash1.png";
import dash2 from "../../images/dash2.png";
import dash3 from "../../images/dash3.png";
import dash4 from "../../images/dash4.png";
import dash5 from "../../images/dash5.png";
import dash6 from "../../images/dash6.png";
import Linechart from "./Linechart";
import Barchart from "./Barchart";
import CustomLineChart from "./Linechart";
import { ObjectSchema } from "yup";
import CustomPieChart from "./CustomPieChart";
import { currencyformater } from "../common/TransForm/currencyformater";
import { Notification } from "../common/Notification";

export default function Dashboard() {
  const [dashborad, setdashborad] = useState();
  const [CashflowData, setCashflowData] = useState([]);
  const [dat, setDat] = useState();
  const [leagues, setleagues] = useState([]);
  const [roundChart, setroundChart] = useState([]);
  const [userChart, setuserChart] = useState([]);
  const [PlayerTop, setPlayerTop] = useState([]);
  const [NonActive, setNonActive] = useState();
  const [leagueFilter, setLeagueFilter] = useState([]);
  const [LocationStatus, setLocationStatus] = useState([]);
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  

  const [filterData, setfilterData] = useState({
    Cashflow: [
      {
        startDate:  new Date(new Date().setDate(new Date().getDate() -30)) ,
        endDate:new Date(), //addDays(new Date(), 7),
        key: "selection",
        range: null,
      },
    ],
    location: [
      {
        startDate:  new Date(new Date().setDate(new Date().getDate() -30)) ,
        endDate:new Date(),  //addDays(new Date(), 7),
        key: "selection",
        range: null,
      },
    ],
    user: [
      {
        startDate:  new Date(new Date().setDate(new Date().getDate() -30)) ,
        endDate:new Date(), //addDays(new Date(), 7),
        key: "selection",
        range: null,
      },
    ],
    leagues: [],
    rounds: [
      {
        startDate:  new Date(new Date().setDate(new Date().getDate() -30)) ,
        endDate:new Date(),  //addDays(new Date(), 7),
        key: "selection",
        range: null,
      },
    ],
    top_players: [
      {
        startDate:  new Date(new Date().setDate(new Date().getDate() -30)) ,
        endDate:new Date(), //addDays(new Date(), 7),
        key: "selection",
        range: null,
      },
    ],
    non_active: [
      {
        startDate:  new Date(new Date().setDate(new Date().getDate() -30)) ,
        endDate:new Date(),  //addDays(new Date(), 7),
        key: "selection",
        range: null,
      },
    ],
  });


  const getDashboradData = async () => {
    try {
      
      const dashboard = await axios.get(
        `${base_url}/api/dashboard/admin-dashboard/summary`,
        Config
      );
      if (dashboard.status == 200) {
        setdashborad(dashboard?.data?.data);
        // setuserChart([
        //   {
        //     name: "Active Users",
        //     value: dashboard?.data?.data?.active_users,
        //     color: "#0FADCF",
        //   },
        //   {
        //     name: "Non-Active Users",
        //     value: dashboard?.data?.data?.in_active,
        //     color: "#3C50E0",
        //   },
        // ]);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification)  
    }
  };

  const getLeaguesData = async () => {
    try {
      const leaguedata = await axios.get(`${base_url}/api/pot/seasons`, Config);
      if (leaguedata.status == 200) {
        const leagueList = leaguedata.data.data.map((item) => {
          return { name: item.display_name, value: item.league_season_id };
        });
        setLeagueFilter(leagueList);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification) 
      console.error("Error fetching data:", error);
    }
  };

  const getUserManagement = async (url, filter) => {
    try {
      if (filter) {
        url =
          url +
          `?end=${formatDate(filter[0].endDate)}&start=${formatDate(
            filter[0].startDate
          )}`;
      }

      const userEngagement = await axios.get(url, Config);
      if (userEngagement.status == 200) {
        setuserChart([
          {
            name: "Active Users",
            value: userEngagement?.data?.data?.active,
            color: "#0FADCF",
          },
          {
            name: "Non-Active Users",
            value: userEngagement?.data?.data?.inactive,
            color: "#3C50E0",
          },
        ]);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification) 
      console.error("Error fetching data:", error);
    }
  };

  const getPopularLeagues = async (url, filter) => {
    try {
      if (filter) {
        url =
          url +
          `?end=${formatDate(filter[0].endDate)}&start=${formatDate(
            filter[0].startDate
          )}`;
      }

      const PopularLeagues = await axios.get(url, Config);
      if (PopularLeagues.status == 200) {
        setleagues(PopularLeagues?.data?.data?.league_players);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification) 
      console.error("Error fetching data:", error);
    }
  };

  const getPlayerDetails = async (url, filter) => {

    try {
      if (filter) {
        url =
          url +
          `?end=${formatDate(filter[0].endDate)}&start=${formatDate(
            filter[0].startDate
          )}`;
      }

      const topPlayer = await axios.get(url, Config);
      if (topPlayer.status == 200) {
        setPlayerTop(topPlayer?.data?.data?.top_players);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification) 
      console.error("Error fetching data:", error);
    }
  };

  const getNonActivePlayerDetails = async (url, filter) => {
    try {
      if (filter) {
        url =
          url +
          `?end=${formatDate(filter[0].endDate)}&start=${formatDate(
            filter[0].startDate
          )}`;
      }

      const nonPlayer = await axios.get(url, Config);
      if (nonPlayer.status == 200) {
        setNonActive(nonPlayer?.data?.data?.inactive);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification) 
      console.error("Error fetching data:", error);
    }
  };
  const getCashFlow = async (url, filter) => {
    try {
      if (filter) {
        url =
          url +
          `?end=${formatDate(filter[0].endDate)}&start=${formatDate(
            filter[0].startDate
          )}`;
      }
      const cashFlow = await axios.get(url, Config);

      if (cashFlow.status == 200) {
        const allDates = new Set([
          ...cashFlow?.data?.data?.commisions.map((item) => item.date),
          ...cashFlow?.data?.data?.payouts.map((item) => item.date),
          ...cashFlow?.data?.data?.contributions.map((item) => item.date),
        ]);

        const sortedDates = Array.from(allDates).sort();
        const combinedData = Array.from(sortedDates).map((date) => {
          const commisionsItem = cashFlow?.data?.data?.commisions.find(
            (item) => item.date === date
          );
          const payoutsItem = cashFlow?.data?.data?.payouts.find(
            (item) => item.date === date
          );
          const contributionsItem = cashFlow?.data?.data?.contributions.find(
            (item) => item.date === date
          );

          return {
            date,
            commisions: commisionsItem ? commisionsItem.value : 0,
            payouts: payoutsItem ? payoutsItem.value : 0,
            contributions: contributionsItem ? contributionsItem.value : 0,
          };
        });

        setCashflowData(
          combinedData
          // {
          //   commisions: {
          //     name: "Admin Fee",
          //     color: "#80CAEE",
          //     data: cashFlow?.data?.data?.commisions,
          //   },
          //   payouts: {
          //     name: "Total Payouts",
          //     color: "#0FADCF",
          //     data: cashFlow?.data?.data?.payouts,
          //   },
          //   contributions: {
          //     name: "Total Contributions",
          //     color: "#3C50E0",
          //     data: cashFlow?.data?.data.contributions,
          //   },
          // }
        );
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification) 
      console.error("Error data:", error?.response?.request?.status);
    }
  };

  const getLocationStatus = async (url, filter) => {
    try {
      if (filter) {
        url =
          url +
          `?end=${formatDate(filter[0].endDate)}&start=${formatDate(
            filter[0].startDate
          )}`;
      }
      const Location = await axios.get(url, Config);
      if (Location.status == 200) {
        setLocationStatus(Location?.data?.data);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification) 
      console.error("Error data:", error?.response?.request?.status);
    }
  };
  const getLeagues = async (url, filter) => {
    try {
      if (filter) {
        url = url + `?league=${filter}`;
      }
      const leagues = await axios.get(url, Config);

      if (leagues.status == 200) {
        let response = leagues?.data?.data;
        setroundChart([
          {
            name: "Active Round",
            value: response?.active_pots,
            color: "#3C50E0",
          },
          {
            name: "Prize Poll/Jackpot Round",
            value: response?.jack_pots,
            color: "#6E80FF",
          },
          {
            name: "Waitlist Round",
            value: response?.waitlist_pots,
            color: "#80CAEE",
          },
          {
            name: "Completed Round",
            value: response?.completed_pots,
            color: "#0FADCF",
          },
        ]);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification) 
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDashboradData();
    getCashFlow(`${base_url}/api/dashboard/admin-dashboard/cashflow`,filterData.Cashflow);
    getLeagues(`${base_url}/api/dashboard/admin-dashboard/potdetails`);
    getPopularLeagues(`${base_url}/api/dashboard/admin-dashboard/potdetails`);
    getUserManagement(
      `${base_url}/api/dashboard/admin-dashboard/user-engagement`,filterData.user
    );
    getPlayerDetails(`${base_url}/api/dashboard/admin-dashboard/playersdetail`,filterData.top_players);
    getNonActivePlayerDetails(
      `${base_url}/api/dashboard/admin-dashboard/playersdetail`
    );
    getLocationStatus(`${base_url}/api/dashboard/admin-dashboard/locationuser`);
    getLeaguesData();
    // getNonActivePlayer();
  }, [filterData]);

  const barChart = [
    { name: "1 months", value: 10 },
    { name: "3 months", value: 10 },
    { name: "6 months", value: 12 },
    { name: "1 year", value: 120 },
  ];

  return (
    <Box>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontSize: "24px",
            paddingTop: 4,
            paddingBottom: 4,
            fontWeight: "bold",
          }}
        >
          Dashboard
        </Typography>
        {/* <Box sx={{ display: "flex" }}>
          <DateFilter />
        </Box> */}
      </Stack>

      <Box
        sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 4 }}
      >
        <CardComponent
          img={dash1}
          amount={dashborad?.active_users ? dashborad?.active_users : 0}
          text={"Total Playing Players"}
        />
        <CardComponent
          img={dash2}
          amount={dashborad?.total_users ? dashborad?.total_users : 0}
          text={"Total Registered Players"}
        />
        <CardComponent
          img={dash3}
          amount={dashborad?.active_pots ? dashborad?.active_pots : 0}
          text={"Active Rounds"}
        />
        <CardComponent
          img={dash4}
          amount={`AED ${currencyformater(
            dashborad?.payouts ? dashborad?.payouts : 0
          )}`}
          text={"Pending Payouts"}
        />
        <CardComponent
          img={dash5}
          amount={`AED ${currencyformater(
            dashborad?.contributions ? dashborad?.contributions : 0
          )}`}
          text={"Total Contribution"}
        />
        <CardComponent
          img={dash6}
          amount={`AED ${currencyformater(
            dashborad?.commisions ? dashborad?.commisions : 0
          )}`}
          text={"Total Admin Fee"}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          marginTop: 5,
          gap: 3,
        }}
      >
        <CustomLineChart
          xAxisLabel="Date"
          yAxisLabel="Value"
          data={CashflowData}
          title={"Cashflow"}
          filters={"date"}
          setfilterData={setfilterData}
          getUrl={`${base_url}/api/dashboard/admin-dashboard/cashflow`}
          setData={setCashflowData}
          filterData={filterData}
          filterkey={"Cashflow"}
          align={"left"}
          getFunction={getCashFlow}
          options={[]}
          downloadUrl={{
            commisions: `${base_url}/api/dashboard/admin-dashboard/report?action=admin_commission_report&fields=current_pot_value,admin_fee,admin_commission,id`,
            contributions: `${base_url}/api/dashboard/admin-dashboard/report?action=purchases_report&fields=player__username,amount,created_at__date,token`,
            payouts: `${base_url}/api/dashboard/admin-dashboard/report?action=pay_outs_report&fields=amount_payable,payment_status,player__username,id`,
          }}
        />
       
        <Barchart
          title={"Location Status"}
          data={LocationStatus}
          filters={"date"}
          align={"right"}
          filterkey={"location"}
          getFunction={getLocationStatus}
          setfilterData={setfilterData}
          filterData={filterData}
          options={[]}
          downloadUrl={`${base_url}/api/dashboard/admin-dashboard/report?action=location_users&fields=users_count,country_name`}
          type={"location"}
          getUrl={`${base_url}/api/dashboard/admin-dashboard/locationuser`}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          marginTop: 3,
          gap: 3,
        }}
      >
        <CustomPieChart
          title={"User Engagement:"}
          filterkey={"user"}
          align={"left"}
          data={userChart}
          filters={"date"}
          setfilterData={setfilterData}
          filterData={filterData}
          getUrl={`${base_url}/api/dashboard/admin-dashboard/user-engagement`}
          setData={setCashflowData}
          getFunction={getUserManagement}
          options={[]}
          downloadUrl={`${base_url}/api/dashboard/admin-dashboard/report?action=user_engagement&params=Active&fields=created_at,last_login,is_active`}
        />

        <Barchart
          title={"Popular Leagues:"}
          filterkey={"leagues"}
          align={"right"}
          data={leagues}
          type={"league"}
          setfilterData={""}
          filterData={{}}
          getUrl={`${base_url}/api/dashboard/admin-dashboard/potdetails`}
          getFunction={getPopularLeagues}
          options={[]}
          downloadUrl={`${base_url}/api/dashboard/admin-dashboard/report?action=league_players&fields=display_name,number_of_players`}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          marginTop: 3,
          gap: 3,
        }}
      >
        <CustomPieChart
          title={"Round Status"}
          filterkey={"leagues"}
          align={"left"}
          data={roundChart}
          filters={"league"}
          setfilterData={setfilterData}
          options={leagueFilter}
          filterData={filterData}
          getUrl={`${base_url}/api/dashboard/admin-dashboard/potdetails`}
          getFunction={getLeagues}
          downloadUrl={`${base_url}/api/dashboard/admin-dashboard/report?action=pot_details&fields=id,status,participants,season__display_name`}
        />
        <Barchart
          title={"Top Players"}
          filterkey={"top_players"}
          align={"right"}
          data={PlayerTop}
          type={"top_players"}
          filters={"date"}
          setfilterData={setfilterData}
          filterData={filterData}
          getUrl={`${base_url}/api/dashboard/admin-dashboard/playersdetail`}
          getFunction={getPlayerDetails}
          options={[]}
          downloadUrl={`${base_url}/api/dashboard/admin-dashboard/report?action=top_players&fields=spent,player__username`}
        />
      </Box>
      
      <Box
        sx={{
          display: "flex",
          marginTop: 3,
          gap: 3,
        }}
      >
        <Barchart
          title={"Non-Active Player"}
          filterkey={"non_active"}
          align={"left"}
          data={NonActive}
          type={"in_active"}
          setfilterData={setfilterData}
          filterData={filterData}
          getUrl={`${base_url}/api/dashboard/admin-dashboard/playersdetail`}
          getFunction={getPlayerDetails}
          options={[]}
          downloadUrl={`${base_url}/api/dashboard/admin-dashboard/report?action=inactive_users_report&fields=username,id,picks_count,period,last_played,inactive_duration,email`}
        />
      </Box>
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />
    </Box>
  );
}

// import { Box } from '@mui/material'
// import React from 'react'

// export default function Dashboard() {
//   return (
//     <Box> Dashboard is under construction!</Box>
//   )
// }
