import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading, setPotSetting, setPotSettingList, setSelectedLeagues, setUsers, setWaitinglistpot } from "../../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../../backendurl/url";
import { useState } from "react";
import UpdatePopup from "../../Leagues/UpdatePopup";
import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";
import { currencyformater } from "../../common/TransForm/currencyformater";


export default function Completepot() {
  const navigate = useNavigate();
  const {PotSettingList} = useSelector((state) => state);
  const [loading, setLoading] = useState(false)
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();
  const token = localStorage.getItem('token')
  const [popup, setPopup] = useState(false)
  const [leaguedetails, setLeagueDetails] = useState({})

  const userTableColumns = () => [
    {
      key: "id",
      label: "Round ID",
      align: "left",
      fontSize: '15px',

      valueRenderer: (data) => {
        return (
          <Link
            component={Typography}
            style={{ color: "#3C50E0", textDecoration: "underline" }}
            to={`completeround/view/${data.id}`}
            onClick={() => dispatch(setWaitinglistpot(data))}
          >
            {data["id"]}
          </Link>
        );
      },
    },
    { key: "category", label: "Round Category", align: "left", fontSize: "15px" },
    { key: "league", label: "League", align: "left", fontSize: "15px" },
    { key: "current_pot_value", label: "Round Value", align: "left", fontSize: "15px" ,
    valueRenderer: (data) => {
      return `${data['pot_currency']}  ${data['current_pot_value']}`
      }
  },
    { key: "admin_fee", label: "Admin Fee %", align: "left", fontSize: "15px",
   
   },
    {
      key: "winners",
      label: "No. of Winners",
      align: "center",
      fontSize: '14px',
      px : "10px",
      valueRenderer: (data) => {
        return (
          <Link
            component={Typography}
            style={{ color: "#3C50E0", textDecoration: "underline" }}
            
            to={`completeround/winner/${data.id}`}
            onClick={() => dispatch(setWaitinglistpot(data))}
          >
            {data["winners"]}
          </Link>
        );
      },
    },
    { key: "winning_amount", label: "Prize Amount Per Winner", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
      return `${data['pot_currency']}  ${currencyformater(data['winning_amount'])}`
      }
  },
  ];

  const getCompltePot = useCallback(async () => {
    try{
      if (_.isEmpty(PotSettingList) && !loading) {
        setLoading(true)
        const response = await Axios.get(`${base_url}/api/pot/pots?status=Completed`, Config);
        if (response?.status == 200) {
          dispatch(setPotSettingList(response?.data?.data))
          dispatch(setPotSetting({}))
        }
        setLoading(false)
      }
    }
 catch(error){
  RefreshToken(error,setErrorMessage,setnotification)
 }
  }, []);

  const gotoAddUser = () => {
    navigate("add");
  };

  useEffect(() => {
    getCompltePot();
  }, []);

  return (
    <>
      <Box  >
        <TransTable
          showSearch={false}
          checkbox={false}
          columns={userTableColumns()}
          rows={PotSettingList}
          addBtnAction={gotoAddUser}
        />
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

      </Box>
    </>
  );
}
