import React, { useEffect, useState } from "react";
import { initForm, validate, getIsDisabled } from "./formUtility";
import { WrapperComponent } from "./WrapperComponent";
import { TransTextInput } from "./TransTextInput";
import { TransButton } from "../TransButton";
import TransDatePicker from "./TransDateField";
import _, { forEach } from "lodash";
import { Formik, Form as FormikForm } from "formik";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import TransFileUpload from "./TransFileUpload";
import TransTextView from "./TransTextView";
import TransIconButton from "./TransIconButton";
import { PnpSelect, pnpSelect } from "./PnpSelect";
import CurrencyInput from "./CurrencyInput";
import { PnpTextArea } from "./PnpTextArea";
import SelectWithInput from "./SelectWithInput";
import { EnergySavingsLeafOutlined } from "@mui/icons-material";
import { element } from "prop-types";

export const TransForm = React.memo(
  ({
    submitAction,
    cancelAction,
    btnClick,
    formInputs,
    data,
    mode,
    passFormData,
    isUpdateFormData = true,
    onDeleteClick,
    cardlength,
    getFormData
  }) => {
    const [formConfigInputs, setFormConfigInputs] = useState([]);
    const [formData, setFormData] = useState({});
    const [validationSchemaState, setValidationSchemaState] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
      const { values, validationSchema } = initForm(formInputs);
      setFormConfigInputs(formInputs);

      if (_.isEmpty(data)) {
        if (isUpdateFormData || _.isEmpty(formData)) {
          setFormData(values);
        }
      } else {
        if (isUpdateFormData || _.isEmpty(formData)) {
          setFormData(data);
        }
      }

      setValidationSchemaState(validationSchema);
    }, [data, formInputs, isUpdateFormData]);

    const onCancel = () => {
      if (cancelAction) {
        cancelAction();
      } else {
        navigate(-1);
      }
    };

    const [disablebtn, setDisableBtn] = useState(true)

    const onSubmit = async (name) => {

      try {
        setIsSubmitting(true);
        const validatedData = await validate(validationSchemaState, formData);
        if (validatedData) {
          submitAction(formData, name);
        }
      } catch (err) {
        updateErrorMessage(err);
      }
      setIsSubmitting(false);
    };

    const onBtnClick = async () => {
      if (btnClick) {
        btnClick(formData)
      }
    };

    const ClickActions = {
      action: onSubmit,
      cancel: onCancel,
      button: onBtnClick,
    };
    const FormObject = {
      text: TransTextInput,
      password: TransTextInput,
      action: TransButton,
      SelectWithInput: SelectWithInput,
      button: TransButton,
      cancel: TransButton,
      wrapper: WrapperComponent,
      select: PnpSelect,
      file: TransFileUpload,
      files: TransFileUpload,
      date: TransDatePicker,
      textview: TransTextView,
      number: TransTextInput,
      iconbutton: TransIconButton,
      currencyinput: CurrencyInput,
      textArea: PnpTextArea
    };


    const onChange = async (key, value) => {
      const newFormData = { ...formData, [key]: value };
      passFormData && passFormData(newFormData, key, value);
      setFormData(newFormData);
      if (getFormData) {
        getFormData(newFormData);
      }
      // let flag = true
      // Object.keys(validationSchemaState.fields).forEach(key => {
      //   element = validationSchemaState.fields[key]
      //   if (element != undefined) {
      //     if (key != 'id' && formData[key] == '') {
      //       flag = false
      //     }
      //   }
      // });


      const validatedData = await validate(validationSchemaState, newFormData);
      if (validatedData) {

        setDisableBtn(false)
      } else {
        setDisableBtn(true)
      }

    };


    const generateForm = (element, key = false) => {

      const { shape, name, type, options, show, customeonchange, isDisabled, submitButton,fileRestriction, ...rest } = element;
      const FormElement = FormObject[element.type] || TransTextInput;
      const props = {
        ...rest,
        name,
        ...(type === "wrapper" && { FormObject, generateForm }),
        onClick: ClickActions[element.type],
        type: type === "confirmPassword" ? "password" : type,
        onChange: onChange,
        customeonchange: customeonchange,
        onDeleteClick: onDeleteClick,
        // isDisabled: getIsDisabled(formData, element, mode, isSubmitting),
        isDisabled: submitButton ? disablebtn ? true : false : isDisabled,
        cardlength: cardlength,
        isSubmitting,
        options: options,
        fileRestriction : fileRestriction ? fileRestriction : null,
        value: type == 'date' && formData[name] == ''?null:formData[name],
        countryCode: 'pot_currency' in formData ? formData['pot_currency'] : 'AED',
        mode,
        formData,
        ...(key && { keyValue: key }),
      };

      return show == false ? '' : <FormElement {...props} />;
    };

    const updateErrorMessage = (err) => {
      const inner = err?.inner;
      let errorFields = _.uniq(inner?.map((inn) => inn.path)) || [];

      let newFormInputs = getErrorMessage(formInputs, inner, errorFields);
      setFormConfigInputs(newFormInputs);
    };

    const getErrorMessage = (fields, inner, errorFields) => {
      return fields.map((field) => {
        if (field.children) {
          field.children = getErrorMessage(field.children, inner, errorFields);
        }
        if (errorFields.includes(field.name)) {
          field.errors = {
            status: true,
            message: inner.find((inn) => field.name === inn.path).errors[0],
          };
        }
        return field;
      });
    };


    return (
      <Formik sx={{ py: 10 }} enableReinitialize initialValues={data || formData}>

        <FormikForm noValidate>
          {formConfigInputs &&
            formConfigInputs.map((element, index) => {
              return (
                <Box key={index} sx={{ mt: 2, px: 6 }}>
                  {element && generateForm(element)}
                </Box>
              );
            })}

        </FormikForm>
      </Formik>
    );
  }
);