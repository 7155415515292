import { TabContext, TabList, TabPanel } from '@mui/lab';
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Typography, Tab, Stack, Button } from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { utcTimetoLocalTimeWithzone } from '../common/TransForm/Localdateformater';
import { Notification } from '../common/Notification';
import ModalPopUp from '../common/TransForm/ModalPopUp';
import { Config } from '../../utils/commonFuctions/Token';
import { RefreshToken } from '../../utils/commonFuctions/RefreshToken';

function LeagueStatus() {
  const navigate = useNavigate();
  const [value, setValue] = useState('active');
  const [popup, setPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [notification,setnotification] = useState(false)
  const [selectedleague, setSelectedLeague] = useState([])
  const [loading, setLoading] = useState(false)
  const [leagueData, setLeagueData] = useState([])

  const gotoAddUser = () => {
    navigate("add");
  };

  const handleNavigate =(data) => {
    navigate('/leagues/details', {state:data} )
  }
  const handleUpdateStatus =async (data) => {
    setSelectedLeague(data)
    setPopup(true)
  }

  const userTableColumns = [
    { key: "league", label: "League ID", align: "left", fontSize: "15px" },
    {
      key: "leaguename",
      label: "League Name",
      align: "left",
      fontSize: '15px',
      valueRenderer: (data) => {
        return (
          <Typography
            style={{ color: "#3C50E0", textUnderlineOffset:7, textDecoration:'underline', cursor:'pointer', width:'180px'  }}
            onClick={() => handleNavigate(data)}
          >
            {data["leaguename"]}
          </Typography>
        );
      },
    },
    { key: "start_date", label: "Start Date", align: "left" ,
    valueRenderer: (data) => {
      let date = utcTimetoLocalTimeWithzone(data["start_date"])
      const [datePart, timePart] = date.split(', ');
      return (
        <Typography sx={{width:'150px'}}> {datePart}</Typography>
        )
    },
  },
    { key: "end_date", label: "End Date", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
      let date = utcTimetoLocalTimeWithzone(data["end_date"])
      const [datePart, timePart] = date.split(', ');
      return (
        <Typography sx={{width:'150px'}}> {datePart}</Typography>
        )
    },
   },
    { key: "total_teams", label: "No. of Teams", align: "left",
    valueRenderer: (data) => {
      return (
        <Typography sx={{width:'150px'}}>{data["total_teams"]}</Typography>
      ) }
    },
    { key: "total_matches", label: "No. of Matches", align: "left",
    valueRenderer: (data) => {
      return (
        <Typography sx={{width:'150px'}}>{data["total_matches"]}</Typography>
      ) } },
    { key: "total_match_week", label: "No. of Match Weeks/Days", align: "left",
    valueRenderer: (data) => {
      return (
        <Typography sx={{width:'150px'}}>{data["total_match_week"]}</Typography>
      ) } },
    {
      key: "action",
      label: "Action",
      align: "center",
      valueRenderer: (data) => {
        return (
          <Box sx={{width:'150px'}}>
           {value == 'active' ? <Button sx={{  color: '#3C50E0', fontSize: '14px', borderRadius: '6px' }}  onClick={()=>handleUpdateStatus(data)}>
              update status   
            </Button> :
            <Button sx={{  color: '#AAAAAA', fontSize: '14px', borderRadius: '6px' }}  >
              update status   
            </Button> }
            
          </Box>
        );
      },
    },
  ]


  const SubmitLeagues =async () => {
    try{
      selectedleague.status = 'Completed'
      const response = await Axios.put(`${base_url}/api/pot/seasons/${selectedleague?.id}`,selectedleague, Config);
      if(response?.status == 200){
        setnotification(false)
        getLeague()
      }else{
        setErrorMessage('League in this Season is Aleardy Exist')
        setnotification(true)
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)  
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getLeague = useCallback(async () => {
    try{
      if (!loading) {     
        setLoading(true)
        const response = await Axios.get(`${base_url}/api/pot/seasons?status=${value}`, Config);
        if (response?.status == 200) {
          setLeagueData(response?.data?.data);
        }
        setLoading(false)
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)  
     }
  }, [value]);

  useEffect(() => {
    getLeague();
  }, [value]);


  return (
    <Box sx={{ width: '100%', typography: 'body1', }}>
      <Stack direction={'row'} sx={{ justifyContent: 'space-between', px: 6, pt: 3 }} alignContent="center">
        <PageTitle pageTitle={'Leagues'} />
        <Button sx={{ backgroundColor: '#3C50E0', color: '#EFF4FB',  '&:hover': {backgroundColor: '#3C50E0', color: '#EFF4FB'}}} onClick={gotoAddUser} >Add new league +</Button>
      </Stack>
      <TabContext value={value} sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList sx={{ px: 6, pt: 3 }} onChange={handleChange}  indicatorColor="secondary" textColor="primary" aria-label="lab API tabs example">
            <Tab label="Active" value="active" sx={{ color: value === 'active' ? '#3C50E0 !important' : '#000', cursor:'pointer' }} />
            <Tab label="Completed" value="completed" sx={{ color: value === 'completed' ? '#3C50E0 !important' : '#000', cursor:'pointer' }} />
          </TabList>
        </Box>
        <TabPanel value="active" sx={{ width: '100%' }}>
          <TransTable
            showSearch={true}
            checkbox={false}
            columns={userTableColumns}
            rows={leagueData}
            showAddBtn={false}
            filter_keys={['league', 'leaguename']}
          />
        </TabPanel>
        <TabPanel value="completed" sx={{ width: '100%' }}>
          <TransTable
            showSearch={true}
            checkbox={false}
            columns={userTableColumns}
            rows={leagueData}
            showAddBtn={false}
            filter_keys={['league', 'leaguename']}

          />
        </TabPanel>
      </TabContext>
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={'Confirm the action ?'}
        subTitle={'Are you Sure You Want to Change The Status to Completed. '}
        onClick={SubmitLeagues}
        save={'Yes, Confirm'}
        cancel={'Exit'}
      />
      <Notification  message = {errorMessage} setopen={setnotification} type ={'error'} show={notification}  />
    </Box>

  )
}

export default LeagueStatus