import { useCallback, useEffect } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedLeagues } from "../../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Capsule from "../../common/Capsule";
import Axios from "axios";
import { base_url } from "../../backendurl/url";
import { useState } from "react";
import ModalPopUp from "../../common/TransForm/ModalPopUp";
import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";

export default function LeagueList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const [selectedleagues, setSelectedleagues] = useState([])
  const [popup, setPopup] = useState(false)
  const [leaguedetails, setLeagueDetails] = useState({})
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')


  const userTableColumns = () => [
    {
      key: "league_id",
      label: "League Id",
      align: "left",
      fontSize: '15px',

      valueRenderer: (data) => {
        return (
          <Link
            component={Typography}
            style={{ color: "black", textDecoration: "none" }}
          >
            {data["league_id"]}
          </Link>
        );
      },
    },
    { key: "name", label: "League Name", align: "left", fontSize: "15px" },
    {
      key: "active",
      label: "Status",
      align: "center",
      fontSize: '14px',
      valueRenderer: (data) => {
        if (data.active == true) {
          return <Capsule data='Active' bgColor='#21965333' color='#219653' />;
        } else {
          return <Capsule data='Inactive' bgColor='#87878714' color='#64748B'  />;
        }
      },
    },
    {
      key: "action",
      label: "Action",
      align: "center",
      valueRenderer: (data) => {
        return (
          <Box>
            <Button onClick={() => onHandelClick(data)} sx={{ backgroundColor: '#F1F5F9', color: '#3C50E0', fontSize: '14px', borderRadius: '6px' }} >{data.active == true ? 'inactive' : 'active'} </Button>
          </Box>
        );
      },
    },
  ];


  const onHandelClick = (data) => {
    setPopup(true)
    setLeagueDetails(data)
  }

  const SubmitChange = async () => {
    try{
      const response = await Axios.patch(`${base_url}/api/pot/configureleagues/${leaguedetails.id}`, { active: !leaguedetails.active, name: leaguedetails.name }, Config);
      if (response?.status == 200) {
        setPopup(false)
      }
      getUsers()
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification) 
   }
  }

  const getUsers = useCallback(async () => {
    try{
      if (_.isEmpty(selectedleagues) && !loading) {
        setLoading(true)
        const response = await Axios.get(`${base_url}/api/pot/configureleagues`, Config);
        if (response?.status == 200) {
          setSelectedleagues(response?.data?.data);
        }
        setLoading(false)
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification) 
    }
   
  }, []);

  const gotoAddUser = () => {
    navigate("add");
    setSelectedleagues(setSelectedLeagues({}));
  };


  const gotToViewUser = (data) => {
    dispatch(setSelectedLeagues(data));
    navigate(`/${data.id}`);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Box>
        <TransTable
          pageTitle="Configuring Leagues"
          subTitle={'Leagues from database are listed below'}
          addBtnTitle={'Configure a new league +'}
          checkbox={false}
          columns={userTableColumns({ gotToViewUser })}
          rows={selectedleagues}
          addBtnAction={gotoAddUser}
          showSearch={true}
          filter_keys={['league_id', 'name']}
        />
         <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />
      </Box>
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={'Confirm the action ?'}
        subTitle={'Are you sure, you want to Change the League Status.'}
        onClick={SubmitChange}
        save={'Confirm'}
        cancel={'Exit'}
      />
    </>
  );
}
