import { useSelector } from "react-redux";
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import {
  Box,
  IconButton,
  Switch,
  Typography,
  Tab,
  Stack,
  Button,
} from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Capsule from "../common/Capsule";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";
function PlayersDetails() {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false)
  const [value, setValue] = useState("a");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  let { state } = location;
  const { users } = useSelector((state) => state);
  const [activePot, setActivepot] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [notification,setnotification] = useState(false)
  const handleNavigate =(data) => {
    navigate('player_id',{state:{'player_details':state,'data':data}})
  }

  const userTableColumns = [
    {
      key: "pot_id", label: "Round ID", align: "left", fontSize: "15px",
      valueRenderer: (data) => {
        return (
          <Typography
            style={{ color: "#3C50E0", textUnderlineOffset:7, textDecoration:'underline', cursor:'pointer', width:'80px'  }}
            onClick={() => handleNavigate(data)}
          >
            {data["pot_id"]}
          </Typography>
        );
      },
    },
    { key: "category", label: "Round Category", align: "left", fontSize: "15px" },
    { key: "leaguename", label: "League", align: "left", fontSize: "15px" },
    {
      key: "pick_status", label: "Player Status", align: "left", fontSize: "15px",
      valueRenderer: (data) => {
        if (data.pick_status == "Win") {
          return <Capsule data='Win' bgColor='#21965333' color='#219653'  />;
        } else if (data.pick_status == "Lost") {
          return <Capsule data='Lost' bgColor='#96592114' color='#965921'  />;
        } else if (data.pick_status == "Active") {
          return <Capsule data='Active' bgColor='#21965333' color='#219653' />;
        } else if(data.pick_status == "JackPot") {
          return <Capsule data='JackPot' bgColor='#21965333' color='#219653' />;
        }

      },
    },
    { key: "prize_amount", label: "Prize Amount Per Winner", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
      return `${data['pot_currency']}  ${data['prize_amount']}`
      }
   },


  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // getUsers()
  };
  const getUsers = useCallback(async () => {
    try{
      if (!loading) {
        setLoading(true);
        const response = await Axios.get(
          `${base_url}/api/pot/playerpots?player=${state?.id}`,
          Config
        );
        if (response?.status == 200) {
          setActivepot(response?.data?.data);
        }
        setLoading(false);
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
  }, [value]);

  const SubmitChange = async () => {

  }

 
  useEffect(() => {
    getUsers();
  }, [users]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={"Player Details"} />

        {/* <Button sx={{ backgroundColor: '#3C50E0', color: '#EFF4FB' }} onClick={gotoAddUser} >Add new league +</Button> */}
      </Stack>
      <Box sx={{ display: "flex", px: 6 }}>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Player ID:</span> {state.serial_no}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>User Name:</span>{state.username}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Name:</span> {state.username}
        </Typography>


      </Box>

      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >

        <PageTitle pageTitle={"Round Played"} />
      </Stack>
      <TransTable
        showSearch={false}
        checkbox={false}
        columns={userTableColumns}
        rows={activePot}
        showAddBtn={false}
      />
      <Box sx={{ display: 'flex', px: 6 }}>
        {/* <TransButton title={'Activate'} onClick={potActivate}/>
                <TransButton onClick = {() => navigate('/pots')} title={'Back'} sx = {{background : '#fff',color : '#1c2434',border : '1px solid #E2E8F0','&:hover': {
      backgroundColor: 'transparent', // Remove the background color change on hover
    },}}  /> */}

      </Box>
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={'Activating pot ?'}
        subTitle={'Are you sure, you want to activate the pot'}
        onClick={SubmitChange}
        save={'Yes, Activate'}
        cancel={'Exit'}
        sx={{ borderRadius: '2px' }}
      />
       <Notification
        message={errorMessage}
        setopen={setnotification}
        type={"error"}
        show={notification}
      />
    </Box>

  );
}

export default PlayersDetails;
