import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../service";
import { setLoading, setSelectedUser, setUsers } from "../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "../common/Iconify";
import Capsule from "../common/Capsule";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { useState } from "react";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";

export default function UserList() {
  const navigate = useNavigate();
  const { users } = useSelector((state) => state);
  const [loading, setLoading] = useState(false)
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();
  const userTableColumns = (params) => [
    {
      key: "id",
      label: "Admin Id",
      align: "left",
      fontSize: '15px',
      valueRenderer: (data) => {
        return (
          // <Link
          //   component={Typography}
          //   style={{ color: "black", textDecoration: "none" }}
          //   to={`/users/edit/${data.id}`}
          //   onClick={() => dispatch(setSelectedUser(data))}
          // >
            data["serial_no"]
          // </Link>
        );
      },
    },
    { key: "username", label: "Admin Name", align: "left", fontSize: "15px" },
    { key: "email", label: "Email", align: "left" },
    { key: "mobile_number", label: "Mobile", align: "left", fontSize: "15px" },

    {
      key: "is_active",
      label: "Status",
      align: "center",
      fontSize: '14px',
      valueRenderer: (data) => {
        if (data.is_active == true) {
          return <Capsule data='Active' bgColor='#21965333' color='#219653' />;
          
        } else {
          return <Capsule data='Inactive' bgColor='#87878714' color='#64748B' />;
        }
      },
    },
    {
      key: "action",
      label: "Action",
      align: "center",
      valueRenderer: (data) => {
        return (
          <Box>
            <IconButton onClick={() => params.gotToViewUser(data)}>
              <Iconify icon="eva:eye-outline" size="small" color="primary.main" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const getUsers = useCallback(async () => {
    try{
      if (!loading) {

        setLoading(true)
        const response = await Axios.get(`${base_url}/api/accounts/user/create?type=admin`, Config);
        if (response?.status == 200) {
          let no = 1
          let data = response?.data?.data
          data.map(item => {
            item.serial_no = no
            no += 1
          })
          dispatch(setUsers(data));
        }
        setLoading(false)
      }
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification)
   }
  }, [users]);

  const gotoAddUser = () => {
    navigate("add");
    dispatch(setSelectedUser({}));
  };

  const gotToEditUser = (data) => {
    dispatch(setSelectedUser(data));
    navigate(`edit/${data.id}`);
  };
  const gotToViewUser = (data) => {
    navigate(`view/${data.id}`);
    delete data?.id
    dispatch(setSelectedUser(data));
  };

  const onSearch = () => { };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Box>
        <TransTable
          pageTitle="Admin Users"
          addBtnTitle={'Add new admin +'}
          showSearch={false}
          checkbox={false}
          columns={userTableColumns({ gotToEditUser, gotToViewUser })}
          rows={users}
          addBtnAction={gotoAddUser}
        />
    <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

      </Box>
    </>
  );
}
