import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { UserForm } from "./components/Users/UserForm";
import UserList from "./components/Users/UserList";
import DashboardLayout from "./layouts/dashboard";
import AuthLayout from "./layouts/auth";
import Login from './components/Auth/LoginPage.js/Login'
import ForgotPassword from "./components/Auth/ForgotPassword";
import GeneralLayout from "./pages/GeneralLayout";
import { useSelector } from "react-redux";
import _ from "lodash";
import Dashboard from "./components/Dashboard/Dashboard";
import VerifyEmail from "./components/Auth/VerifyEmail";
import VerifyOTP from "./components/Auth/VerifyOTP";
import { ConfigForm } from "./components/Configurations/ConfigForm";
import TeamsList from "./components/Configurations/TeamsList";
import { NewTeamForm } from "./components/Configurations/NewTeamForm";
import LeagueList from "./components/Configurations/Leagues/leaguesList";
import { LeagueForm } from "./components/Configurations/Leagues/leaguesForm";
import MatchList from "./components/Configurations/Match/MatchList";
import { MatchweekForm } from "./components/Configurations/Match/MatchForm";
import LeagueStatus from "./components/Leagues/leagueStatus";
import { LeaguesForm } from "./components/Leagues/leagueForm";
import Purchases from './components/Purchases/Purchases'
import { PotForm } from './components/PotSetting/PotForm'
import LeagueDetails from "./components/Leagues/LeagueDetails";
import Players from './components/Players/Players'
import AutoFeachFixtures from "./components/Leagues/AutoFetchFixtures";
import Pots from "./components/PotSetting/Pots";
import { WaitlistForm } from "./components/PotSetting/CreatePotForm";
import PotIdDetails from "./components/PotSetting/WaitListPots/PotIdDetails";
import ActivePotId from "./components/PotSetting/ActivePots/ActivePotId";
import ActiveMatchWeek from "./components/PotSetting/ActivePots/ActiveMatchWeek";
import CompletepotsId from "./components/PotSetting/CompletePots/CompletepotsId";
import CompleteMatchWeek from "./components/PotSetting/CompletePots/CompleteMatchWeek";
import CompleteWinnerDetails from "./components/PotSetting/CompletePots/CompleteWinnerDetails";
import { Manualfixtures } from "./components/Leagues/manualFixtures";
import PlayersDetails from "./components/Players/PlayerDetails";
import PlayersIdDetails from "./components/Players/PlayerIdDeatails";
import ChangePlayerDetails from "./components/Players/ChangePlayerDetails";
import Payouts from './components/Payouts/payouts'
import { useEffect } from "react";
import CustomerQuery from "./components/CustomerQuery/CustomerQuery";
// ----------------------------------------------------------------------

export default function AppRouter() {
  const { userInfo } = useSelector((state) => state);
  const token = localStorage.getItem('token')

  return useRoutes([

    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: !_.isEmpty(token) ? (
            <Navigate to="rounds" />
          ) : (
            <Navigate to="auth/login" />
          ),
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "rounds",
          element: <GeneralLayout title="pots" />,
          children: [
            { path: "", element: <Pots /> },
            { path: "add", element: <WaitlistForm mode="new" /> },
            { path: "edit/:id", element: <WaitlistForm mode="edit" /> },
            { path: "view/:id", element: <PotIdDetails mode="view" /> },
            { path: "activeround/view/:id", element: <ActivePotId mode="view" /> },
            { path: "activeround/matchweek/:id", element: <ActiveMatchWeek mode="view" /> },
            { path: "completeround/view/:id", element: <CompletepotsId mode="view" /> },
            { path: "completeround/matchweek/:id", element: <CompleteMatchWeek mode="view" /> },
            { path: "completeround/winner/:id", element: <CompleteWinnerDetails mode="view" /> },


          ],
        },
        {
          path: "users",
          element: <GeneralLayout title="Users" />,
          children: [
            { path: "", element: <UserList /> },
            { path: "add", element: <UserForm mode="new" /> },
            { path: "edit/:user_uuid", element: <UserForm mode="edit" /> },
            { path: "view/:user_uuid", element: <UserForm mode="view" /> },
          ],
        },
        {
          path: "configurations",
          element: <GeneralLayout title="configurations" />,
          children: [{ path: "", element: <ConfigForm mode='view' /> },
          { path: "roundparameters", element: <ConfigForm mode='edit' /> },
          { path: "leagues", element: <LeagueList /> },
          { path: "leagues/add", element: <LeagueForm mode="new" /> },
          { path: "teams", element: <TeamsList /> },
          { path: "teams/add", element: <NewTeamForm mode="new" /> },
          { path: "matchweeks", element: <MatchList /> },
          { path: "matchweeks/add", element: <MatchweekForm mode="new" /> },
          ],
        },

        {
          path: "leagues",
          element: <GeneralLayout title="Leagues" />,
          children: [{ path: "", element: <LeagueStatus /> },
          { path: "add", element: <LeaguesForm mode="new" /> },
          { path: "add/autofixture", element: <AutoFeachFixtures /> },
          { path: "add/manualfixture", element: <Manualfixtures /> },
          { path: "details", element: <LeagueDetails mode="new" /> },
          { path: "details/add", element: <PotForm mode="new" /> },
          { path: "details/edit/:user_uuid", element: <PotForm mode="edit" /> }
          ],
        },

        {
          path: "purchases",
          element: <GeneralLayout title="Purchases" />,
          children: [{ path: "", element: <Purchases /> },
          ],
        },
        {
          path: "payouts",
          element: <GeneralLayout title="Payouts" />,
          children: [{ path: "", element: <Payouts /> },
          ],
        },
        {
          path: "players",
          element: <GeneralLayout title="players" />,
          children: [{ path: "", element: <Players /> },
          { path: "add", element: <ChangePlayerDetails mode="new" /> },
          { path: "edit/:player_uuid", element: <playerForm mode="edit" /> },
          { path: "view/:player_uuid", element: <playerForm mode="view" /> },
          { path: "player_details", element: <PlayersDetails mode="view" /> },
          { path: "player_details/player_id", element: <PlayersIdDetails mode="view" /> },
        ],
        },
        {
          path: "customer-query",
          element: <GeneralLayout title="customer-query" />,
          children: [{ path: "", element: <CustomerQuery /> },
          // { path: "add", element: <ChangePlayerDetails mode="new" /> },
          // { path: "edit/:player_uuid", element: <playerForm mode="edit" /> },
          // { path: "view/:player_uuid", element: <playerForm mode="view" /> },
          // { path: "player_details", element: <PlayersDetails mode="view" /> },
          // { path: "player_details/player_id", element: <PlayersIdDetails mode="view" /> },
        ],
        },
      ],
    },

    // {
    //   path: "*",
    //   element: !_.isEmpty(token) ? (
    //     <Navigate to="dashboard" />
    //   ) : (
    //     <Navigate to="auth/login" />
    //   ),
    // },

    // !_.isEmpty(token)
    //   ? {
    //     path: "auth/*",
    //     element: <Navigate to="/" replace />,
    //   }
    //   : {},
  ]);
}
