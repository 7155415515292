import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../service";
import { setLoading, setSelectedUser, setUsers } from "../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import editicon from "../../images/editIcon.png";

import Iconify from "../common/Iconify";
import Capsule from "../common/Capsule";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { useState } from "react";
import { utcTimetoLocalTimeWithzone } from "../common/TransForm/Localdateformater";
import { Dateformater } from "../common/TransForm/dateFormater";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { Notification } from "../common/Notification";
import CustomePopUp from "./CustomePopUp";

export default function CustomerQuery() {
  const navigate = useNavigate();
  const { users } = useSelector((state) => state);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const [showPopUp,setShowpopUp] = useState(false)
  const [popUp,setPopup] = useState(false)
  const [tokenValue,setaddTokenValue] = useState(false)
  const [userId, setUserId] = useState("");
  const tokenFlag=process.env.REACT_APP_TOKEN_FLAG
  const [errorMessage, setErrorMessage] = useState('')
  const [notification,setnotification] = useState(false)
  const [currentItem,setCurrentItem] = useState({})

 
  
  const userTableColumns = (params) => {
     let tablejson =  [
      {
        key: "id",
        label: "Inquiry ID",
        align: "left",
        fontSize: '15px',
      },
      { key: "email", label: "Email", align: "left", fontSize: "15px" },
      { key: "issue_type", label: "Inquiry Type", align: "left", fontSize: "15px", },
      { key: "issue", label: "Inquiry Description", align: "left" },
      { key: "status", label: "Inquiry Status", align: "left", fontSize: "15px" ,
      valueRenderer: (data) => {
        
        return (
          <Box>
            {data.status == 'Closed' || data.status == 'Not_Issue'  ? (
              <Button
                sx={{
                  color: "#219653",
                  backgroundColor:'#F7F9FC',
                  fontSize: "14px",
                  borderRadius: "5px",
                  width:'100px',
                  border:'1px solid #219653',
                  cursor:'default'
                }}
              >
                {data.status == 'Closed' ? 'Resolved':'Not A Issue'}
              </Button>
            ) : (
              <Button
                sx={{
                  color: "#DD8A0D",
                  backgroundColor: "#FEFFE1",
                  fontSize: "13px",
                  width:'100px',
                  borderRadius: "5px ",
                  border:'1px solid #DD8A0D',
                  cursor:'default'
                }}
              >
                {data.status}
              </Button>
            )}
          </Box>
        );
      },
    
    
    },
      {
        key: "last_played", label: "Actions", align: "left", fontSize: "15px",
        valueRenderer: (data) => {
            return (
              <Typography
                style={{ color: "#3C50E0", textUnderlineOffset:7, textDecoration:'underline', cursor:'pointer'  }}
                onClick={() => {setShowpopUp(true)
                  setCurrentItem(data)
                }}

              >
                {'View'}
              </Typography>
            );
          },
    
      },
    ];
    if(tokenFlag == 'false'){
      let availabletoken = tablejson.find(item=>item.key == 'Addtoken')
      const index = tablejson.indexOf(availabletoken);
if (index > -1) { 
  tablejson.splice(index, 1);
}
    }
    return tablejson
  }



const savePopup = async(data) =>{
  const response = await Axios.put(`${base_url}/api/accounts/tickets/${currentItem.id}`,data,Config);

    getUsers()

}


 const getUsers = useCallback(async () => {
  try{
  
      setPopup(false)
      setLoading(true)
      const response = await Axios.get(`${base_url}/api/accounts/tickets`, Config);

      if (response?.status == 200) {
        let no = 1
        let data = response?.data?.data
        dispatch(setUsers(data));
      }
      setLoading(false)
      setPopup(false)
      
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
  }, [users]);

  const gotoAddUser = () => {
    navigate("add");
    dispatch(setSelectedUser({}));
  };


  useEffect(() => {
    getUsers();
 
  }, []);

  const handleToken = (e) => {
    setaddTokenValue(e.target.value)
  }


  //key => tokens , user -> post 
  const TokenValue = async() => {
    try{
      const response = await Axios.post(
        `${base_url}/api/accounts/buytokens`,
        { 
          // ...userId,
          tokens : parseInt(tokenValue),
          user : userId.id
          //  available_tokens: userId.available_tokens ? parseInt(userId.available_tokens) + parseInt(tokenValue) : parseInt(tokenValue)
          },

        Config
      );
      if (response?.data?.status == "Success") {
       getUsers()
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
    
  }


  return (
    <>
      <Box>
        <TransTable
          pageTitle="Customer Query"
          showAddBtn={false}
          showSearch={true}
          checkbox={false}
          columns={userTableColumns()}
          rows={users}
          addBtnAction={gotoAddUser}
          filter_keys={['id', 'email', 'issue_category','issue','status']}
        />
        <Notification
        message={errorMessage}
        setopen={setnotification}
        type={"error"}
        show={notification}
      />
      </Box>
{showPopUp?<CustomePopUp
      title={'Query Details'}
       save={'Save'}
        cancel={'Exit'}
         openpopup={showPopUp}
         setPopup={setShowpopUp} 
         data={currentItem}
         onClick={savePopup}
      />:''}
      

      <ModalPopUp
        setPopup={setPopup}
        openpopup={popUp}
        title={'Add Token Value'}
        subTitle = {`${userId.username} | Available Token : ${userId.available_tokens}`}
        onChange = {handleToken}
        popupType = {'player'}
        saveBtnBg={"red"}
        onClick={TokenValue}
        save={'Add Token'}
        cancel={"Exit"}
      />
    </>
  );
}
