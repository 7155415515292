import { RefreshToken } from "./RefreshToken"

function tokenConfig() {
    
    let token =  localStorage.getItem('token')

    return   {headers: {
      "Authorization": 'Bearer ' + token
    }
  }
}



export const Config = tokenConfig()


