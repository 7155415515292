import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading, setPotSetting, setPotSettingList, setSelectedLeagues, setUsers, setWaitinglistpot } from "../../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../../backendurl/url";
import { useState } from "react";
import UpdatePopup from "../../Leagues/UpdatePopup";
import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";


export default function ActivePots() {
  const navigate = useNavigate();
  const {PotSettingList} = useSelector((state) => state);
  const [loading, setLoading] = useState(false)
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();
  const token = localStorage.getItem('token')

  const ActivePotList = () => [
    {
      key: "id",
      label: "Round ID",
      align: "left",
      fontSize: '15px',
      valueRenderer: (data) => {
        return (
          <Link
            component={Typography}
            style={{ color: "#3C50E0", textDecoration: "underline" }}
            to={`activeround/view/${data.id}`}
            onClick={() => dispatch(setWaitinglistpot(data))}
          >
            {data["id"]}
          </Link>
        );
      },
    },
    { key: "category", label: "Round Category", align: "left", fontSize: "15px" },
    { key: "league", label: "League", align: "left", fontSize: "15px" },
    { key: "current_pot_value", label: "Round Value", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
      return `${data['pot_currency']}  ${data['current_pot_value']}`
      }
     },
    { key: "admin_fee", label: "Admin Fee %", align: "left", fontSize: "15px",
   
   },
    {key: "joined_picks",label: "No. of Picks Joined",align: "center",fontSize: '14px',px : "10px"},
    { key: "Start MW", label: "Start MW", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
      return `${data?.match_weeks?.start_match_week}`
      }
   },
    { key: "current_match_week", label: "Current MW", align: "left", fontSize: "15px" ,
    valueRenderer: (data) => {
      return `${data?.match_weeks?.current_match_week}`
      }
  
  },
  ];
  const getActivePot = useCallback(async () => {
    try{
      if (_.isEmpty(PotSettingList) && !loading) {
        setLoading(true)
        const response = await Axios.get(`${base_url}/api/pot/pots?status=Active`, Config);
        if (response?.status == 200) {
          dispatch(setPotSettingList(response?.data?.data))
          dispatch(setPotSetting({}))
        }
        setLoading(false)
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
   
  }, []);


  const gotoAddUser = () => {
    navigate("add");
  };

  useEffect(() => {
    getActivePot();
  }, []);

  return (
    <>
      <Box  >
        <TransTable
          showSearch={false}
          checkbox={false}
          columns={ActivePotList()}
          rows={PotSettingList}
          addBtnAction={gotoAddUser}
        />
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

      </Box>
    </>
  );
}
