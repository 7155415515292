import { TabContext, TabList, TabPanel } from "@mui/lab";
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Typography, IconButton, Tab, Stack, Button } from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { Notification } from "../common/Notification";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import editicon from "../../images/editIcon.png";
import Iconify from "../common/Iconify";
import { utcTimetoLocalTimeWithzone } from "../common/TransForm/Localdateformater";

function Payouts() {
  const navigate = useNavigate();
  const [value, setValue] = useState("Failed Payouts");
  const [popup, setPopup] = useState(false);
  
  const [loading, setLoading] = useState(false);
  const [paymentData, setpaymentData] = useState([]);
  const [amountAdd, setAmountAdd] = useState(false);
  const [Amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("AED");
  const tokenFlag = process.env.REACT_APP_TOKEN_FLAG;
  const [paymentMode, setpaymentMode] = useState("Cash");
  const [playerdetails, setplayerdetails] = useState("");
  const [errorMessage, setErrorMessage] = useState('')
  const [notification,setnotification] = useState(false)

  const handleNavigate = (data) => {
    navigate("/leagues/details", { state: data });
  };

  const headdata = [
    {
      player_id: "",
    },
  ];

  const userTableColumns = () => {
    let userTableColumns = [
      {
        key: "id",
        label: "Player ID",
        align: "left",
        fontSize: "15px",
        // valueRenderer: (data) => {
        //   return (
        //     <Typography
        //       style={{ color: "#3C50E0", textUnderlineOffset: 7, textDecoration: 'underline', cursor: 'pointer', width: '180px' }}
        //       onClick={() => handleNavigate(data)}
        //     >
        //       {data["id"]}
        //     </Typography>
        //   );
        // },
      },
      {
        key: "payment_type",
        label: "Payment Method",
        align: "left",
        valueRenderer: (data) => {
          return (
            <Typography sx={{ width: "150px" }}>
              {" "}
              {data["payment_type"]}
            </Typography>
          );
        },
      },
      {
        key: "player_name",
        label: "Player Name",
        align: "left",
        fontSize: "15px",
      },

      // {
      //   key: "pot",
      //   label: "Round ID",
      //   align: "left",
      //   fontSize: "15px",

      //   // valueRenderer: (data) => {
      //   //   return (
      //   //     <Typography sx={{ width: '150px' }}> {data["pot"]}</Typography>
      //   //   )
      //   // },
      // },
      {
        key: "updated_at",
        label: "Payment Date",
        align: "left",
        valueRenderer: (data) => {
          let date = utcTimetoLocalTimeWithzone(data["updated_at"]);
          const [datePart, timePart] = date.split(", ");
          return <Typography sx={{ width: "150px" }}>{datePart}</Typography>;
        },
      },
      {
        key: "amount_payable",
        label: "Amount Payable",
        align: "left",
        valueRenderer: (data) => {
          return (
            <Typography sx={{ width: "150px" }}>
              {" "}
              {data.currency_code} {data["amount_payable"]}
            </Typography>
          );
        },
      },
      {
        key: "amount_paid",
        label: "Amount Paid",
        align: "left",
        // valueRenderer: (data) => {
        //   return (
        //     <Typography sx={{ width: '150px' }}>{data["amount_paid"]}</Typography>
        //   )
        // }
        valueRenderer: (data) => {
          return (
            <Box sx={{ width: "180px" }}>
              <IconButton
                sx={{
                  "&:hover": {
                    // backgroundColor: 'transparent'
                    borderRadius: "12px",
                  },
                }}
                onClick={() => {
                  setAmountAdd(true);
                  setplayerdetails(data);
                  setAmount(0);
                  setCurrency(data.currency_code);
                }}
              >
                <Typography
                  sx={{
                    color: "#3C50E0",
                    display: "flex",
                    gap: 2,
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {data.currency_code} {data.amount_paid}
                  <Iconify
                    icon={editicon}
                    type="image"
                    size="small"
                    color="primary.main"
                  />
                </Typography>
              </IconButton>
            </Box>
          );
        },
      },
      {
        key: "payment_status",
        label: "Payment Status",
        align: "center",
        fontSize: '14px',
        
        valueRenderer: (data) => {
          const colors = {
            Success :{ color:'#219653',backgroundColor:'#21965333'},
            Failure:{ color:'#E42C2C',backgroundColor:'#FFE8E1'},
            Idle:{ color:'#DD8A0D',backgroundColor:'#FEFFE1'}
          }
        return <Button sx={{
  
          backgroundColor: `${colors[data?.payment_status] ? colors[data?.payment_status]?.backgroundColor :'#FFE8E1'}`,
           color:`${colors[data?.payment_status]?.color ?colors[data?.payment_status]?.color:'#E42C2C'}`}}>{data?.payment_status}</Button>
        },
      },
      // {
      //   key: "payment_status",
      //   label: "Payment Status",
      //   align: "center",
      //   valueRenderer: (data) => {
      //     return (
      //       <Box sx={{ width: "150px" }}>
      //         {/* <Button sx={{  color: '#219653', backgroundColor:' ', fontSize: '14px', borderRadius: '6px' }}  >
      //             Success
      //         </Button> */}
      //         {data?.payment_status == 'Success' ? (
      //           <Button
      //             sx={{
      //               color: "#219653",
      //               backgroundColor:'#21965314',
      //               fontSize: "14px",
      //               borderRadius: "6px",
      //             }}
      //           >
      //             Success
      //           </Button>
      //         ) : (
      //           <Button
      //             sx={{
      //               color: "#E42C2C ",
      //               backgroundColor: "#FFE8E1",
      //               fontSize: "14px",
      //               borderRadius: "6px",
      //             }}
      //           >
      //             Failure
      //           </Button>
      //         )}
      //       </Box>
      //     );
      //   },
      // },
    ];
    if (tokenFlag == "false") {
      let availabletoken = userTableColumns.find(
        (item) => item.key == "amount_paid"
      );
      const index = userTableColumns.indexOf(availabletoken);
      if (index > -1) {
        userTableColumns.splice(index, 1);
      }
    }
    return userTableColumns;
  };

  const SubmitLeagues = async () => {
    // console.log('submit successfully')
  };

  const SubmitAmount = async () => {
    //post api
    try {
      if (!loading) {
        setLoading(true);
        const response = await Axios.put(
          `${base_url}/api/pot/payouts/${playerdetails.id}`,
          {
            // ...userId,
            // tokens : parseInt(tokenValue),
            amount_paid: Amount,
            // payment_type : paymentMode,
            payment_type: "Cash",
          },
          Config
        );
        if (response?.status == 200) {
          getLeague();
        }
        setLoading(false);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification)  

    }

    setAmountAdd(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getLeague = useCallback(async () => {
    // setpaymentData(data)
    try {
      if (!loading) {
        setLoading(true);
        const response = await Axios.get(`${base_url}/api/pot/payouts`, Config);
        if (response?.status == 200) {
          setpaymentData(response?.data?.data);
        }
        setLoading(false);
      }
    } catch (error) {
      RefreshToken(error,setErrorMessage,setnotification)  

    }
  }, [value]);

  useEffect(() => {
    getLeague();
  }, [value]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={"Payouts"} />
      </Stack>
      {/* <TabContext value={value} sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList sx={{ px: 6, pt: 3 }} onChange={handleChange} indicatorColor="secondary" textColor="primary" aria-label="lab API tabs example">
            <Tab label="Failed Payouts" value="Failed Payouts" sx={{ color: value === 'Failed Payouts' ? '#3C50E0 !important' : '#000', cursor: 'pointer' }} />
            <Tab label="Completed Payouts" value="Completed Payouts" sx={{ color: value === 'Completed Payouts' ? '#3C50E0 !important' : '#000', cursor: 'pointer' }} />
          </TabList>
        </Box>
        <TabPanel value="Failed Payouts" sx={{ width: '100%' }}>
          <TransTable
            showSearch={true}
            checkbox={false}
            columns={userTableColumns}
            rows={paymentData}
            showAddBtn={false}
            filter_keys={['league', 'leaguename']}
          />
        </TabPanel>
        <TabPanel value="Completed Payouts" sx={{ width: '100%' }}>
          <TransTable
            showSearch={true}
            checkbox={false}
            columns={userTableColumns}
            rows={paymentData}
            showAddBtn={false}
            filter_keys={['league', 'leaguename']}

          />
        </TabPanel>
      </TabContext> */}
      <TransTable
        showSearch={true}
        checkbox={false}
        columns={userTableColumns()}
        rows={paymentData}
        showAddBtn={false}
        filter_keys={["player_name", "pot", "updated_at", "payment_status"]}
      />
      <ModalPopUp
        openpopup={amountAdd}
        setPopup={setAmountAdd}
        title={`${playerdetails.player_name} | Payable Amount :${playerdetails.currency_code} ${playerdetails.amount_payable}`}
        subTitle={"Enter Amount"}
        // subTitle = {`${userId.username} | Available Token : ${userId.available_tokens}`}
        popupType={"payout"}
        //payment methos
        // values={{amount:Amount,countryCode:currency,paymentMode:paymentMode}}

        values={{ amount: Amount, countryCode: currency }}
        onChange={(key, value) => {
          if (key == "pot_currency") {
            // setpaymentMode(value)
            setCurrency(value);
          } else {
            setAmount(value);
          }
        }}
        onClick={SubmitAmount}
        save={"Yes, Confirm"}
        cancel={"Exit"}
      />
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={"Confirm the action ?"}
        subTitle={"Are you Sure You Want to Change The Status to Completed. "}
        onClick={SubmitLeagues}
        save={"Yes, Confirm"}
        cancel={"Exit"}
      />
      <Notification
        message={errorMessage}
        setopen={setnotification}
        type={"error"}
        show={notification}
      />
    </Box>
  );
}

export default Payouts;
