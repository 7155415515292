/* eslint-disable import/no-anonymous-default-export */

const initialState = {
  userInfo: {},
  selectedUser: {},
  users: [],
  transporters: [],
  selectedLeagues: [],
  selectedTeam: [],
  isSystemLeague: false,
  feacthData: [],
  matchweek: [],
  selectedTransporter: {},
  roles: [],
  zones: [],
  districts: [],
  Waitinglistpot: {},
  tripdetails: [],
  selectedTripDetail: {},
  institutions: [],
  institutionTypes: [],
  selectedInstitutions: {},
  deliveryHubs: [],
  FormData: {},
  ActiveMatchWeek : [],

  notification: {
    message: "",
    type: "",
    show: false,
  },
  PotSettingList: [],
  PotSetting: {},
  isLoading: false,
  navOpen : false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "login":
      return Object.assign({}, state, { userInfo: action.payload });

    case "setusers":
      return Object.assign({}, state, { users: action.payload });

    case "setSelectedUser":
      return Object.assign({}, state, { selectedUser: action.payload });

    case "settransporters":
      return Object.assign({}, state, { transporters: action.payload });

    case "setSelectedTransporter":
      return Object.assign({}, state, { selectedTransporter: action.payload });

    case 'setNavOpen':
      return Object.assign(Boolean,state,{navOpen:action.payload})
    // case "setSelectedLeagues":
    //     return Object.assign({}, state, { selectedLeagues: Array.isArray(action.payload) ? action.payload : [action.payload] });    
    case "setSelectedLeagues":
      return Object.assign({}, state, { selectedLeagues: action.payload });
    case "setPotSettingList":
      return Object.assign({}, state, { PotSettingList: action.payload });


    case "setPotSetting":
      return Object.assign({}, state, { PotSetting: action.payload });

    case "setActiveMatchWeek":
      return Object.assign({}, state, { ActiveMatchWeek: Array.isArray(action.payload) ? action.payload : [action.payload] });


    case "setFormData":
      return Object.assign({}, state, { FormData: action.payload });


    case "setSelectedTeam":
      return Object.assign({}, state, { selectedTeam: Array.isArray(action.payload) ? action.payload : [action.payload] });

    case "setIsSystemLeague":
      return Object.assign({}, state, { isSystemLeague: action.payload });

    case "setFeacthData":
      return Object.assign({}, state, { feacthData: action.payload });

    case "setmatchweek":
      return Object.assign({}, state, { matchweek: Array.isArray(action.payload) ? action.payload : [action.payload] });

    case "setzones":
      return Object.assign({}, state, { zones: action.payload });

    case "setroles":
      return Object.assign({}, state, { roles: action.payload });

    case "setdistricts":
      return Object.assign({}, state, { districts: action.payload });

    case "setDeliveryHubs":
      return Object.assign({}, state, { deliveryHubs: action.payload });

    case "setloading":
      return Object.assign({}, state, { isLoading: action.payload });

    case "setinstitutions":
      return Object.assign({}, state, { institutions: action.payload });

    case "setSelectedInstitutions":
      return Object.assign({}, state, { selectedInstitutions: action.payload });

    case "setInstitutionsTypes":
      return Object.assign({}, state, { institutionTypes: action.payload });


    case "setnotification":
      return Object.assign({}, state, { notification: action.payload });

    case "setWaitinglistpot":
      return Object.assign({}, state, { notification: action.payload });

    case "setTripdetails":
      return Object.assign({}, state, { tripdetails: action.payload });

    case "setSelectedTripDetail":
      return Object.assign({}, state, { selectedTripDetail: action.payload });

    default:
      return state;
  }
};
