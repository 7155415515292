import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../service";
import { setLoading, setSelectedUser, setUsers } from "../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import editicon from "../../images/editIcon.png";

import Iconify from "../common/Iconify";
import Capsule from "../common/Capsule";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { useState } from "react";
import { utcTimetoLocalTimeWithzone } from "../common/TransForm/Localdateformater";
import { Dateformater } from "../common/TransForm/dateFormater";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { Notification } from "../common/Notification";

export default function UserList() {
  const navigate = useNavigate();
  const { users } = useSelector((state) => state);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const [popUp,setPopup] = useState(false)
  const [tokenValue,setaddTokenValue] = useState(false)
  const [userId, setUserId] = useState("");
  const tokenFlag=process.env.REACT_APP_TOKEN_FLAG
  const [errorMessage, setErrorMessage] = useState('')
  const [notification,setnotification] = useState(false)

  const handleNavigate =(data) => {
    navigate('player_details', {state:data} )
  }
  
  const userTableColumns = (params) => {
     let tablejson =  [
      {
        key: "id",
        label: "Player ID",
        align: "left",
        fontSize: '15px',
        valueRenderer: (data) => {
          return (
            <Typography
              style={{ color: "#3C50E0", textUnderlineOffset:7, textDecoration:'underline', cursor:'pointer', width:'80px'  }}
              onClick={() => handleNavigate(data)}
            >
              {data["serial_no"]}
            </Typography>
          );
        },
  
      },
      { key: "username", label: "User Name", align: "left", fontSize: "15px" },
      { key: "first_name", label: "Name", align: "left", fontSize: "15px", },
      { key: "email", label: "Email", align: "left" },
      { key: "mobile_number", label: "Mobile", align: "left", fontSize: "15px" },
      {
        key: "last_played", label: "Last Played Date", align: "left", fontSize: "15px",
        valueRenderer: (data) => {
            if(data.last_played == null){
              return <Typography> N/A</Typography>
            }else{
              return <Typography>{Dateformater(data['last_played'])}</Typography>
            }
          
        },
      },
      { key: "available_tokens", label: "Available Token", align: "left", fontSize: "15px" },
      { key: "pots_count", label: "Total Rounds", align: "left", fontSize: "15px" },
      { key: "active_pots_count", label: "Active Rounds", align: "left", fontSize: "15px" },
      { key: "gainlostamount", label: "Total Gain/Loss", align: "left", fontSize: "15px" },
   {
      key: "Addtoken",
      label: "Add Tokens",
      align: "center",
      valueRenderer: (data) => {
        return (
          <Box sx={{ width: "180px"}}>
            <IconButton
              sx={{
                "&:hover": {
                  // backgroundColor: 'transparent'
                  borderRadius: "12px",
                },
              }}
              onClick={() => {
                setPopup(true)
                setUserId(data)}

              }
            >
              <Typography
                sx={{
                  color: "#3C50E0",
                
                  padding : '5px',
                  borderRadius:'10px',
                 
                  display: "flex",
                  gap: 2,
                  "&:hover": {
                    backgroundColor: "transparent",

                  },
                }}
                data={data.available_tokens ? data.available_tokens : 0}
              >
                Add Tokens
                <Iconify
                  icon={editicon}
                  type="image"
                  size="small"
                  color="primary.main"
                /> 
                
              </Typography>
            </IconButton>
          </Box>
        );
      },
    },
      {
        key: "is_active",
        label: "Status",
        align: "center",
        fontSize: '14px',
        valueRenderer: (data) => {
          if (data.is_active == true) {
            return <Capsule data='Active' bgColor='#21965333' color='#219653' sx={{width:'130px'}} />;
          } else {
            return <Capsule data='Inactive' bgColor='#87878714' color='#64748B' sx={{width:'130px'}} />;
          }
        },
      }
    ];
    if(tokenFlag == 'false'){
      let availabletoken = tablejson.find(item=>item.key == 'Addtoken')
      const index = tablejson.indexOf(availabletoken);
if (index > -1) { 
  tablejson.splice(index, 1);
}
    }
    return tablejson
  }






 const getUsers = useCallback(async () => {
  try{
  
      setPopup(false)
      setLoading(true)
      const response = await Axios.get(`${base_url}/api/accounts/user/create?type=player`, Config);
      if (response?.status == 200) {
        let no = 1
        let data = response?.data?.data
        data.map(item => {
          item.serial_no = no
          no += 1
        })
        dispatch(setUsers(data));
      }
      setLoading(false)
      setPopup(false)
      
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
  }, [users]);

  const gotoAddUser = () => {
    navigate("add");
    dispatch(setSelectedUser({}));
  };

  // {
  //   key: "available_tokens",
  //   label: "Add Tokens",
  //   align: "center",
  //   valueRenderer: (data) => {
  //     return (
  //       <Box sx={{ width: "180px"}}>
  //         <IconButton
  //           sx={{
  //             "&:hover": {
  //               // backgroundColor: 'transparent'
  //               borderRadius: "12px",
  //             },
  //           }}
  //           onClick={() => {
  //             if(tokenFlag == 'true' ){

              
  //             setPopup(true)
  //             setUserId(data)}

  //           }}
  //         >
  //           <Typography
  //             sx={{
  //               color: "#3C50E0",
              
  //               padding : '5px',
  //               borderRadius:'10px',
               
  //               display: "flex",
  //               gap: 2,
  //               "&:hover": {
  //                 backgroundColor: "transparent",

  //               },
  //             }}
  //             data={data.available_tokens ? data.available_tokens : 0}
  //           >
  //             Add Tokens
  //             <Iconify
  //               icon={editicon}
  //               type="image"
  //               size="small"
  //               color="primary.main"
  //             /> 
              
  //           </Typography>
  //         </IconButton>
  //       </Box>
  //     );
  //   },
  // },
  useEffect(() => {
    getUsers();
 
  }, []);

  const handleToken = (e) => {
    setaddTokenValue(e.target.value)
  }


  //key => tokens , user -> post 
  const TokenValue = async() => {
    try{
      const response = await Axios.post(
        `${base_url}/api/accounts/buytokens`,
        { 
          // ...userId,
          tokens : parseInt(tokenValue),
          user : userId.id
          //  available_tokens: userId.available_tokens ? parseInt(userId.available_tokens) + parseInt(tokenValue) : parseInt(tokenValue)
          },

        Config
      );
      if (response?.data?.status == "Success") {
       getUsers()
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
    
  }


  return (
    <>
      <Box>
        <TransTable
          pageTitle="Players"
          addBtnTitle={'Change Player Status +'}
          showSearch={true}
          checkbox={false}
          columns={userTableColumns()}
          rows={users}
          addBtnAction={gotoAddUser}
          filter_keys={['username', 'email', 'mobile_number']}
        />
        <Notification
        message={errorMessage}
        setopen={setnotification}
        type={"error"}
        show={notification}
      />
      </Box>

      <ModalPopUp
        setPopup={setPopup}
        openpopup={popUp}
        title={'Add Token Value'}
        subTitle = {`${userId.username} | Available Token : ${userId.available_tokens}`}
        onChange = {handleToken}
        popupType = {'player'}
        saveBtnBg={"red"}
        onClick={TokenValue}
        save={'Add Token'}
        cancel={"Exit"}
      />
    </>
  );
}
