import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useCallback, useEffect, useState } from "react";
// material
// import { Divider } from '@mui/material';
import Divider from "@mui/material/Divider";
import {
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Box,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "../common/Iconify";
// components
//import dayjs, { Dayjs } from 'dayjs';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchNotFound from "./SearchNotFound";
import _ from "lodash";
import { TransButton } from "./TransButton";
import { PageTitle } from "./PageTitle";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  // transition: theme.transitions.create(["box-shadow", "width"], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter,
  // }),
  // "&.Mui-focused": { width: 320 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(filter_keys, array, comparator, query, onSearch) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    if (onSearch) {
      return onSearch(array, query);
    }
    return filter(
      array,
      (row) =>
        getStringifiedValuesForRow(row, filter_keys)
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

function getStringifiedValuesForRow(row, keys) {
  return _.compact(keys.map(key => row[key])).join(" ");
}

// function getStringifiedValuesForRow(row) {
//   return _.compact(_.values(row)).join(" ");
// }

export default function TransTable({
  tableAlign = 'center',
  filter_keys,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  columns,
  pageTitle,
  subTitle,
  rows,
  addBtnTitle,
  addBtnAction,
  selecteable,
  onSearch,
  showDateFilters = false,
  dateFilterKey,
  showSearch = true,
  btnposition = 'top',
  showAddBtn = true,
  customsx
}) {
  const [page, setPage] = useState(0);

  const [selected, setSelected] = useState([]);

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [order, setOrder] = useState("");

  const [orderBy, setOrderBy] = useState("");

  const [rowCount, setRowCount] = useState(rows?.length);

  const [selectedRowsCount, setSelectedRowsCount] = useState(selected?.length);

  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(null);

  const [search, setSearch] = useState()

  const SearchItem = () => {
    setFilterName(search);
    setPage(0)
  }

  useEffect(() => {
    setRowCount(rows?.length);
    setSelectedRowsCount(selected?.length);
  }, [rows, selected]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    // setFilterName(event.target.value);
    setSearch(event.target.value)
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const filteredRows = applySortFilter(
    filter_keys,
    rows,
    getComparator(order, orderBy),
    filterName,
    onSearch
  );
  const isDataNotFound = filteredRows?.length === 0;

  const filteredData = filteredRows?.filter((item) => {
    const itemTimestamp = Date.parse(item[dateFilterKey]);
    const startTimestamp = Date.parse(startDate);
    const endTimestamp = Date.parse(endDate) + 86400000;

    if (startTimestamp && endTimestamp) {
      return itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp;
    } else if (startTimestamp) {
      return itemTimestamp >= startTimestamp;
    } else if (endTimestamp) {
      return itemTimestamp <= endTimestamp;
    } else if (!startTimestamp && !endTimestamp) {
      return true;
    }


  });

  const getValue = (row, col, rowIndex) => {
    const { key, valueRenderer, valueFormatter } = col;
    return valueRenderer
      ? col.valueRenderer(row, rowIndex)
      : valueFormatter
      ? valueFormatter(row, rowIndex)
      : _.get(row, key)!=null? _.get(row, key) :'-';
  };

  return (
    <>
      {pageTitle && <Box
        display="flex"
        justifyContent="space-between"
        sx={{ mb: 0, px: 6, pt: 3, pb: 0 }}
      >

        <Box>
          <PageTitle pageTitle={pageTitle} />

          {subTitle && (
            <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
              {subTitle}
            </Typography>
          )}

          {showDateFilters ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={0}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ pt: 0.5 }} />
                  )}
                  inputProps={{ type: "search" }}
                />
              </Stack>

              <Stack spacing={0}>
                <DatePicker
                  value={endDate}
                  inputFormat="DD/MM/YYYY"
                  format="DD-MM-YYYY"
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="End Date" sx={{ pt: 0.5 }} />
                  )}
                  inputProps={{ type: "search" }}
                />
              </Stack>
            </LocalizationProvider>
          ) : null}
        </Box>
        {showAddBtn && btnposition == 'top' ? (
          <TransButton
            title={addBtnTitle}
            onClick={addBtnAction}
          />
        )
          : null}
      </Box>}


      {showSearch ? (
        <Box sx={{ display: "flex", px: 6, }}>
          <TextField
            value={search}
            onInput={handleFilterByName}
            placeholder="Search"
            sx={{ width: "90%", borderRadius: '3px' }}
          />
          <Box
            onClick={SearchItem}
            sx={{
              display: "flex",
              backgroundColor: "#3C50E0",
              ml: 1,
              color: "#EFF4FB",
              borderRadius: '3px',
              justifyContent: "justify-between ! important",
              px: 2,
              width: '134px',
              alignItems: "center",
              cursor: 'pointer'
            }}
          >
            <Typography sx={{ mr: 4 }}>Search</Typography>
            <Iconify
              icon="eva:search-fill"
              sx={{ color: "#fff", height: "18px" }}
            />
          </Box>
        </Box>
      ) : null}
      {/* <Divider sx={{mb:2,height : 4}} /> */}

      <TableContainer sx={{ 
        minWidth: 800,
        width:'95%',
        mx:'auto',
        overflowX:'scroll',
         py: 3 }}>
        <Table>
          <TableHead
            sx={{ background: "#F7F9FC",border: "1px solid #E8ECEF" }}
          >
            <TableRow
              sx={{
                background: "#F9FAFC",
                fontSize: "16px",
                border: "1px solid #E8ECEF",
              }}
            >
              {selecteable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedRowsCount > 0 && selectedRowsCount < rowCount
                    }
                    checked={rowCount > 0 && selectedRowsCount === rowCount}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {_.cloneDeep(columns).map((headCell, i) => (
                
                <TableCell
                  sx={{ background: "#F9FAFC",textAlign : tableAlign,  }}
                  key={i}
                  align={headCell.align || "center"}
                  sortDirection={orderBy === headCell.key ? order : false}
                >
                  <TableSortLabel
                    hideSortIcon
                    active={orderBy === headCell.key}
                    direction={orderBy === headCell.key ? order : "asc"}
                    onClick={createSortHandler(headCell.key)}
                  >
                    <Typography fontWeight={700}>{headCell.label}</Typography>
                    {orderBy === headCell.key ? (
                      <Box sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{}}>
            {filteredData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                const { id } = row;
                const isItemSelected = selected.indexOf(id) !== -1;

                return (
                  <TableRow
                    hover
                    key={rowIndex}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    {selecteable && (
                      <TableCell sx={{ fontSize: "16px" }} padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, id)}
                        />
                      </TableCell>
                    )}
                    {_.cloneDeep(columns).map((col, i) => {
                      return (
                        <TableCell
                          sx={{ fontSize: "16px",textAlign : tableAlign,  }}
                          key={i}
                          align={col.align || "center"}
                        >
                          {getValue(row, col, rowIndex)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>

                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>

          {isDataNotFound && (
            <TableBody sx={{width:'100%'}}>
              <TableRow>
                <TableCell align="center" colSpan={14} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName ? filterName : 0} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: showAddBtn && btnposition == 'bottom' ? 'space-between' : 'end', px: 4 }}>

        {showAddBtn && btnposition == 'bottom' ? (
          <TransButton
            // sx={{
            //   background: "#3C50E0",
            //   "&:hover": {
            //     backgroundColor: "#3C50E0", // Change to whatever color you want
            //   },
            // }}
            title={addBtnTitle}
            onClick={addBtnAction}
          />
        )
          : null}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData ? filteredData?.length : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>


    </>
  );
}
