import { InputAdornment } from "@mui/material";
import lockicon from "../../../../images/lockIcon.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function userFormInputs(values) {
  return [
    {
      shape: "div",
      type: "wrapper",
      className: "",
      sx: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridColumnGap: "50px",
      },
      children: [
        {
          shape: "input",
          type: "text",
          name: "id",
          // handleChange: "",
          className: "border",
          title: "Admin Id",
          label: "Admin Id",
          placeholder: "ID",
          isDisabled : true,
          inputAdornments:{
              end: <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment>,
            },
          width: "100%",
          // validation: {
          //   required: true,
          // },
        },
        {
          shape: "input",
          type: "text",
          name: "username",
          // handleChange: "",
          className: "border",
          title: "Admin Name",
          label: "Admin Name",
          placeholder: "Enter your name",
          width: "100%",
          validation: {
            required: true,
          },
        },

      ],
    },
    {
      shape: "div",
      type: "wrapper",
      className: "",
      sx: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridColumnGap: "50px",
        mt: 6,
      },
      children: [
        {
          shape: "input",
          type: "email",
          name: "email",
          // handleChange: "",
          className: "border",
          title: "Email",
          label: "Email",
          placeholder: "Enter your email",
          width: "100%",
          validation: {
            required: true,
          },
        },
        {
          shape: "input",
          type: "number",
          name: "mobile_number",
          // handleChange: "",
          className: "border",
          title: "Mobile",
          label: "Mobile",
          placeholder: "Enter your mobile",
          width: "100%",
          validation: {
            required: true,
            minLength:10,
          },
        },

      ],
    },
    {
      shape: "div",
      type: "wrapper",
      className: "",
      sx: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridColumnGap: "50px",
        mt: 6,
      },
        children: [
        {
          shape: "input",
          type: values?.mode == 'new' ? "text" : "select",
          name: "is_active",
          // handleChange: "",
          className: "border",
          title: "Status",
          label: "Status",
          options: values?.mode == 'new' ? "" : [{name:'Active',id:true}, {name:'Inactive',id:false}],
          isDisabled : values?.mode == 'new' ? true : false,
          inputAdornments:{
              end: values?.mode == 'new' ? <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment> :'',
            },
          placeholder: values?.mode == 'new' ? "New user" : "User",
          width: "100%",
          
          // validation: {
          //   required: true,
          // },
        },
        {
          shape: "input",
        //  type: values.eyeOpen? "text" : "password",
        type:'password',
          name: "password",
          // handleChange: "",
          title: "password",
          label: "Password",
          className: "",
          placeholder: "Password",
          width:"100%",
          validation: {
            required:  values?.mode == 'new' ? true : false,
            minLength:8,
            requiredMessage: "Password can't be empty",
          },
          inputAdornments:{
            end:  <InputAdornment 
            sx={{cursor: 'pointer'}} position="start">
          {values.eyeOpen ? <FaEye/> : <FaEyeSlash/>}
          </InputAdornment>,
          },
          show: values?.mode == 'new' ? true : false
        }
      ],
    },  
    {
      shape: "div",
      type: "wrapper",
      className: "",
      sx: { display: "flex", justifyContents: "flex-start", mt: 6 },
      children: [
        {
          shape: "button",
          type: "action",
          title: values.mode === "view" ? "Edit" :values.mode == 'new' ? 'Add' : "Save Changes",
          // submitButton : values.mode === "view" ? false : true ,
          name: "Add",
          validation: {
            hiddenRules: [
              {
                mode: "view",
              },
            ],
          },
        },
        {
          shape: "button",
          type: "cancel",
          title: "Cancel",
          variant: "outlined",
          name: "cancel",
          sx: {
            mr: 2,
          },
        },
      ],
    },
    // {

    //     shape:"select",
    //     type: "select",
    //     label: "Role",
    //     name: "role",
    //     className:"border",
    //     validation: {
    //         required:true
    //     },
    //     options: [
    //         {
    //             label: "Admin",
    //             value: "admin"
    //         },
    //         {
    //             label: "User",
    //             value: "user"
    //         }
    //     ]
    // },
    // {
    //     type: "number",
    //     shape: "input",
    //     label: "Phone Number",
    //     name: "mobile",
    //     className: "border",
    //     title:"mobile",
    //     validation: {
    //         required: true,
    //         minLength: 10,
    //         maxLength:10
    //     }
    // },
  ];
}
