import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { useSelector } from "react-redux";
import { Notification } from "../../components/common/Notification";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setSelectedTeam,
  setSelectedUser,
  setUsers,
  setNavOpen
} from "../../redux/action";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 12;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  backgroundColor: '#E2E8F0',
  flexGrow: 1,
  overflow: "auto",
  height: "90vh",
  paddingTop: 0,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const {isLoading,navOpen} = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if(!localStorage.getItem('token')){
      navigate('/auth/login')
    }
    dispatch(setNavOpen(open))
console.log(navOpen,'navOpen')
  }, [open]);
  return (
    <RootStyle>
      <DashboardSidebar  isOpenSidebar={open}
        onCloseSidebar={() => setOpen(!open)}
      />
      <Box sx={{ width: `${open ? '95%' : '83%'}`, height:'100vh'}}>
        <DashboardNavbar />
        <MainStyle>{!isLoading && <Outlet />}</MainStyle>
      </Box>
    </RootStyle>
  );
}
