import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import {
  Box,
  IconButton,
  Switch,
  Typography,
  Tab,
  Stack,
  Button,
} from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../service";
import {
  setLoading,
  setPotSettingList,
  setSelectedTeam,
  setSelectedUser,
  setUsers,
} from "../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "../common/Iconify";
import Capsule from "../common/Capsule";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import palette from "../../theme/palette";
import { Dateformater } from "../common/TransForm/dateFormater";
import ModalPopUp from "../common/TransForm/ModalPopUp";
// import PotSetting from "../PotSetting/PotSetting";
import PotSetting from "./PotSetting";

import FixtureResults from "../Leagues/FixtureResults";
import WaitlistPots from "./WaitListPots/WaitlistPots";
import ActivePots from "./ActivePots/ActivePots";
import Completepot from "./CompletePots/Completepot";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";

function Pots() {
  const navigate = useNavigate();
  const [value, setValue] = useState("Waitlist");
  const [loading, setLoading] = useState(false);
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  
  const dispatch = useDispatch();

  const gotoAddUser = () => {
    navigate("add");
  };



  const handleChange = (event, newValue) => {
    setValue(newValue);
    // getUsers()
  };
  const getUsers = useCallback(async () => {
    try{
      if (!loading) {
        setLoading(true);
        const response = await Axios.get(
          `${base_url}/api/pot/pots?status=${value}`,
          Config
        );
  
        if (response?.status == 200) {
          dispatch(setPotSettingList(response?.data?.data));
        }
        setLoading(false);
      }
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification)

   }
  }, [value]);

  useEffect(() => {
    getUsers();
  }, [value]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={"Rounds"} />

        <Button sx={{ backgroundColor: '#3C50E0', color: '#EFF4FB',  '&:hover': {backgroundColor: '#3C50E0', color: '#EFF4FB'}}} onClick={gotoAddUser} >Create new Round  +</Button>
      </Stack>
     
      <TabContext value={value} sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            sx={{ px: 6, pt: 3 }}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="primary"
            aria-label="lab API tabs example"
          >
            <Tab
              label="Waitlist Rounds"
              value="Waitlist"
              sx={{
                mx: 3,
                fontSize: "16px",
                fontFamily: "Urbanist",
                fontWeight : '600',
                color: value === "a" ? "#3C50E0 !important" : "inherit",
              }}
            />
            <Tab
              label="Active Rounds"
              value="Active"
              sx={{
                mx: 3,
                fontSize: "16px",
                fontFamily: "Urbanist",
                color: value === "b" ? "#3C50E0 !important" : "inherit",
              }}
            />
            <Tab
              label="Completed Rounds"
              value="Completed"
              sx={{
                mx: 3,
                fontSize: "16px",
                fontFamily: "Urbanist",
                fontWeight: "600",
                color: value === "c" ? "#3C50E0 !important" : "inherit",
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="Waitlist" sx={{ width: "100%" }}>
          <WaitlistPots />
        </TabPanel>
        <TabPanel value="Active"  sx={{ width: "100%" }}>
          <ActivePots />
        </TabPanel>
        <TabPanel value="Completed" sx={{ width: "100%" }}>
         <Completepot/>
        </TabPanel>
      </TabContext>
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

    </Box>
  );
}

export default Pots;
