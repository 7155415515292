import { createStore } from "redux";
import reducer from "./reducer";

const initialState = {
  userInfo: {},
  selectedUser: {},
  users: [],
  transporters: [],
  selectedLeagues: [],
  selectedTransporter:{},
  selectedTeam:[],
  isSystemLeague:false,
  feacthData:[],
  matchweek: [],
  roles: [],
  zones: [],
  districts: [],
  institutions: [],
  tripdetails: [],
  selectedTripDetail: {},
  institutionTypes: [],
  selectedInstitutions: {},
  deliveryHubs: [],
  PotSettingList : [],
  PotSetting :{},
  FormData : {},
  Waitinglistpot:{},
  ActiveMatchWeek:[],
  notification: {
    message: "",
    type: "",
    show: false,
  },
  isLoading: false,
  navOpen:false

};
function configureStore(state = initialState) {
  return createStore(reducer, state);
}

export default configureStore;
