import { useSelector } from "react-redux";
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import {
  Box,
  IconButton,
  Switch,
  Typography,
  Tab,
  Stack,
  Button,
} from "@mui/material";
import { PageTitle } from "../../common/PageTitle";
import { useDispatch } from "react-redux";
import {
  setActiveMatchWeek,
  setPotSetting,

} from "../../../redux/action";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import Iconify from "../../common/Iconify";

import Capsule from "../../common/Capsule";
import Axios from "axios";
import { base_url } from "../../backendurl/url";

import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";
function ActiveMatchWeek() {

  const { PotSetting,ActiveMatchWeek } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation()
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {matchweek,pot_id} = location.state
  const { id } = useParams("id");


  const userTableColumns = [
    { key: "player", label: "Players", align: "left", fontSize: "15px",},
    { key: "pick", label: "Picks No", align: "left", fontSize: "15px" },
    { key: "match", label: "Match Selected", align: "left", fontSize: "15px" },
    { key: "prediction", label: "Pick", align: "left", fontSize: "15px" },
    { key: "pick_result", label: "Player Status", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
        if (data.pick_result == "Win") {
          return <Capsule data='Win' bgColor='#21965333' color='#219653' />;
        } else if(data.pick_result == "Lost"){
          return <Capsule data='Lost' bgColor='#96592114' color='#965921' />;
        }
        else {
          return <Capsule data='Open' bgColor='#96592114' color='#965921' />;
        }
      },
 },
  ];

  const getUsers = async() => {
    try{
      setLoading(true);
      const response = await Axios.get(
        `${base_url}/api/pot/pots/${pot_id}`,
        Config
      );
      dispatch(setPotSetting(response?.data?.data));
      
        const matchweek = await Axios.get(
          `${base_url}/api/pot/potmatchweekplayers?pot=${pot_id}&match_week=${id}`,
          Config
        );
        dispatch(setActiveMatchWeek(matchweek?.data?.data));
      
     
      setLoading(false);
    }catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }


  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={matchweek} />

        {/* <Button sx={{ backgroundColor: '#3C50E0', color: '#EFF4FB' }} onClick={gotoAddUser} >Add new league +</Button> */}
      </Stack>
      <Box sx={{ display: "flex", px: 6 }}>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round ID:</span>  {PotSetting?.id}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Category:</span>  {PotSetting?.category}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>League:</span>  {PotSetting?.league}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Value:</span>  {PotSetting?.pot_currency} {PotSetting?.current_pot_value}
        </Typography>
      </Box>
          <TransTable
            showSearch={false}
            checkbox={false}
            columns={userTableColumns}
            rows={ActiveMatchWeek}
            showAddBtn={false}
          />
          <Box sx = {{display : 'flex',px:6}}>
          </Box>
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

    </Box>
    
  );
}

export default ActiveMatchWeek;
