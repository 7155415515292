import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedTeam } from "../../redux/action";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";

export default function TeamsList() {
  const navigate = useNavigate();
  const {selectedTeam} = useSelector((state) => state);
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();
  
  const userTableColumns = () => [
    {
      key: "team_id",
      label: "Team Id",
      align: "left",
      fontSize: '15px',
    },
    { key: "name", label: "Team Name", align: "left", fontSize: "15px" },
    { key: "groundname", label: "Home Venue", align: "left" },
    { key: "leaguename", label: "League Name", align: "left", fontSize: "15px" }   
  ];

  const getTeamList = useCallback(async () => {
    try{
      
      const response = await Axios.get(`${base_url}/api/pot/teams`, Config);
      if (response?.status == 200) {
        dispatch(setSelectedTeam(response?.data?.data));
    }
    
    }
    catch(error) {
      RefreshToken(error,setErrorMessage,setnotification) 
    }
     
  }, []);

  const gotoAddUser = () => {
    navigate("add");
    dispatch(setSelectedTeam({}));
  };


  useEffect(() => {
    getTeamList();
  }, []);

  return (
    <>
      <Box  >
        <TransTable
          pageTitle="Configuring Teams"
          subTitle={'Teams from database are listed below'}
          addBtnTitle={'Configure a new team +'}
          showSearch={true}
          checkbox={false}
          columns={userTableColumns()}
          rows={selectedTeam}
          addBtnAction={gotoAddUser}
          filter_keys ={['leaguename','groundname','name','team_id']}
        />
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

      </Box>
    </>
  );
}
